// src/components/DashboardHeader.js
import React from 'react';

const DashboardHeader = ({ businessCount, onAddBusiness, onGoHome }) => {
  return (
    <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
      <div>
        <h1 className="text-2xl md:text-3xl font-bold text-gray-800">Business Dashboard</h1>
        {/* <p className="text-gray-600 mt-1">
          {businessCount === 0
            ? "You haven't added any businesses yet"
            : businessCount === 1
            ? "Managing 1 business"
            : `Managing ${businessCount} businesses`}
        </p> */}
      </div>
      
      {/* remove option for multiple businesses */}
      {/* {businessCount > 0 && (
        <button
          onClick={onAddBusiness}
          className="mt-4 md:mt-0 flex items-center bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md transition duration-200"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
          Add New Business
        </button>
      )} */}
    </div>
  );
};

export default DashboardHeader;