// src/pages/Dashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import BusinessCard from '../components/BusinessCard';
import BusinessForm from '../components/BusinessForm';
import DashboardHeader from '../components/DashboardHeader';
import LoadingSpinner from '../components/LoadingSpinner';

const Dashboard = () => {
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddForm, setShowAddForm] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  // const [stats, setStats] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchBusinesses();
  }, []);

  const fetchBusinesses = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/business`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setBusinesses(response.data.businesses);
      
      // If there's at least one business, fetch stats for the first one
      if (response.data.businesses.length > 0) {
        fetchBusinessStats(response.data.businesses[0].id);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        toast.error('Failed to load businesses');
        console.error('Error fetching businesses:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchBusinessStats = async (businessId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/business/${businessId}/stats`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      // setStats(response.data.stats);
      setSelectedBusiness(businessId);
    } catch (error) {
      console.error('Error fetching business stats:', error);
      toast.error('Could not load business statistics');
    }
  };

  const handleAddBusiness = async (businessData) => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/business`,
        businessData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setBusinesses([...businesses, response.data.business]);
      setShowAddForm(false);
      toast.success('Business added successfully');
      
      // Select the newly added business
      setSelectedBusiness(response.data.business.id);
      fetchBusinessStats(response.data.business.id);

      // go home after submission
      navigate('/home');
    } catch (error) {
      console.log('inside error', error.response)
      toast.error(error.response?.data?.message || 'Failed to add business');
      console.error('Error adding business:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteBusiness = async (businessId) => {
    if (!window.confirm('Are you sure you want to delete this business? This action cannot be undone.')) {
      return;
    }
    
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/business/${businessId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Remove from state
      const updatedBusinesses = businesses.filter(business => business.id !== businessId);
      setBusinesses(updatedBusinesses);
      
      // If the deleted business was selected, select another one if available
      if (selectedBusiness === businessId) {
        if (updatedBusinesses.length > 0) {
          setSelectedBusiness(updatedBusinesses[0]._id);
          fetchBusinessStats(updatedBusinesses[0]._id);
        } else {
          setSelectedBusiness(null);
          // setStats(null);
        }
      }
      
      toast.success('Business deleted successfully');
    } catch (error) {
      toast.error(error.response?.data?.error || 'Failed to delete business');
      console.error('Error deleting business:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRequestReview = (businessId) => {
    navigate(`/request-review/${businessId}`);
  };

  const handleViewReviews = (businessId) => {
    navigate(`/reviews/${businessId}`);
  };

  if (loading && businesses.length === 0) {
    return <LoadingSpinner />;
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-6xl">
      <DashboardHeader 
        businessCount={businesses.length}
        onAddBusiness={() => setShowAddForm(true)}
      />
      
      {businesses.length === 0 && !showAddForm ? (
        <div className="bg-white shadow-md rounded-lg p-8 text-center mt-8">
          <h2 className="text-2xl font-semibold text-gray-700 mb-4">Welcome to your dashboard!</h2>
          <p className="text-gray-600 mb-6">Get started by adding your business info.</p>
          <button
            onClick={() => setShowAddForm(true)}
            className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-6 rounded-md transition duration-200"
          >
            Add My Business
          </button>
        </div>
      ) : (
        <div className="mt-8 grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Business List - Left sidebar */}
          <div className="lg:col-span-1">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Your Business</h2>
            <div className="space-y-4">
              {businesses.map(business => (
                <BusinessCard
                  key={business.id}
                  business={business}
                  isSelected={selectedBusiness === business.id}
                  onSelect={() => fetchBusinessStats(business.id)}
                  onEdit={() => navigate(`/edit-business/${business.id}`)}
                  onDelete={() => handleDeleteBusiness(business.id)}
                  onRequestReview={() => handleRequestReview(business.id)}
                  onViewReviews={() => handleViewReviews(business.id)}
                  onGoHome={() => navigate('/home')}
                />
              ))}
            </div>
          </div>

          {/* Main Content - Right area */}
          <div className="lg:col-span-2">
            {showAddForm ? (
              <div className="bg-white shadow-md rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold text-gray-800">Add New Business</h2>
                  <button
                    onClick={() => setShowAddForm(false)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <BusinessForm onSubmit={handleAddBusiness} />
              </div>
            ) : selectedBusiness ? (
              <p></p>
            ) : (
              <div className="bg-white shadow-md rounded-lg p-6 text-center">
                <p className="text-gray-600">Select a business to view stats and details</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;