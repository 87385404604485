// src/pages/Dashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import LoadingSpinner from '../components/LoadingSpinner';
import QRCodeGenerator from '../components/QRCodeGenerator';


const Dashboard = () => {
  const [business, setBusiness] = useState({});
  const [loading, setLoading] = useState(true);
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [option, setOption] = useState('');
  const [reviewUrl, setReviewUrl] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchFirstBusiness();
  }, []);

  const fetchFirstBusiness = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/business`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if(response.data.businesses.length > 0) {
        const biz = response.data.businesses[0];
        setBusiness(biz);

        // Format the review URL - use Google Place ID if available, otherwise create a generic search URL
        if (biz?.googlePlaceId) {
          setReviewUrl(`https://search.google.com/local/writereview?placeid=${biz.googlePlaceId}`);
        } else {
          // If no place ID, create a search URL with the business name and maybe address
          const searchTerm = `${biz?.name} ${biz?.address?.city || ''}`.trim();
          setReviewUrl(`https://www.google.com/search?q=${encodeURIComponent(searchTerm)}`);
        }
      }
    
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        toast.error('Failed to load businesses');
        console.error('Error fetching businesses:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleQRClick = () => {
    setOption('qr');
  };
  
  const handleTextClick = () => {
    setOption('phone');
  };
  
  const handleEmailClick = () => {
    setOption('email');
  };

  // Requesting a review by email
  const handleEmailSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {      
      // Create the email content
      var subject;
      var body;
      if(business?.emailTemplate?.message) {
        subject = encodeURIComponent(`${business?.emailTemplate?.subject}`);
        body = encodeURIComponent(`Hi ${customerName}, \n\n` + `${business?.emailTemplate?.message} \n\n` + `Visit this link to leave a review: ${reviewUrl}\n\n`);
      } else {
        subject = encodeURIComponent(`Please Review ${business?.name}`);
        body = encodeURIComponent(
          `Hi ${customerName},\n\n` +
          `Thank you for choosing ${business?.name}! We'd love to hear about your experience.\n\n` +
          `Could you please take a moment to leave us a review? Your feedback helps us improve our services and helps others find us.\n\n` +
          `Click here to leave a review: ${reviewUrl}\n\n` +
          `Thank you for your time!\n\n` +
          `Best regards,\n` +
          `The ${business?.name} Team`
        );
      }
      
      // Open the default email client
      window.location.href = `mailto:${customerEmail}?subject=${subject}&body=${body}`;
      
      toast.success('Email app opened successfully');
      
      setTimeout(() => {
        setCustomerEmail('');
        setCustomerName('');
        setOption('');
        setLoading(false);
      }, 1000);
      
    } catch (error) {
      toast.error('Failed to open email application');
      console.error('Error:', error);
      setLoading(false);
    }
  };

  function normalizePhoneNumber(phoneNumber) {
    // Strip all non-numeric characters
    const digitsOnly = phoneNumber.replace(/\D/g, '');
    
    // Check if the number has 10 digits (US format without country code)
    if (digitsOnly.length === 10) {
      // Add US country code (+1)
      return "+1" + digitsOnly;
    } 
    // If it already has 11 digits and starts with 1 (likely has country code)
    else if (digitsOnly.length === 11 && digitsOnly.startsWith('1')) {
      return "+" + digitsOnly;
    }
    // Invalid number format
    else {
      console.warn('Invalid phone number format:', phoneNumber);
      return phoneNumber; // Return original as fallback
    }
  }

  // Requesting a review by text
  const handlePhoneSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    try {      
      // Create the email content
      var body;
      if(business?.emailTemplate?.message) {
        body = encodeURIComponent(`Hi ${customerName}, \n\n` + `${business?.emailTemplate?.message} \n\n` + `Visit this link to leave a review: ${reviewUrl}`);
      } else {
        body = encodeURIComponent(
          `Hi ${customerName},\n\n` +
          `Thank you for choosing ${business?.name}! We'd love to hear about your experience.\n\n` +
          `Could you please take a moment to leave us a review? Your feedback helps us improve our services and helps others find us.\n\n` +
          `Click here to leave a review: ${reviewUrl}\n\n` +
          `Thank you for your time!\n\n` +
          `Best regards,\n` +
          `The ${business?.name} Team`
        );
      }

      // format phone number
      const formattedPhone = normalizePhoneNumber(customerPhone);
      
      // Open the default phone client
      window.location.href = `sms:${formattedPhone}?body=${body}`;
      
      toast.success('Email app opened successfully');
      
      setTimeout(() => {
        setCustomerEmail('');
        setCustomerName('');
        setCustomerPhone('');
        setOption('');
        setLoading(false);
      }, 1000);
      
    } catch (error) {
      toast.error('Failed to open email application');
      console.error('Error:', error);
      setLoading(false);
    }
  }

  if (loading && business.length === 0) {
    return <LoadingSpinner />;
  }

  return (
    <div className="container mx-auto px-4 max-w-6xl space-y-4">
      <div className="flex flex-col items-center w-full max-w-md mx-auto p-6 rounded-3xl">
        {/* Header Section */}
        <div className="w-full text-center space-y-4 mb-4">
          <img 
              src="/logo.png" 
              alt="Logo"
              className="h-20 w-auto mx-auto object-contain"
            />
          <h1 className="text-3xl md:text-4xl font-bold text-black">The Review App</h1>
          <p className="text-md my-4 text-black">Ask for a review</p>
        </div>
        
        {/* Buttons */}
        <div className="w-full space-y-4">
          <button
            onClick={handleQRClick}
            className={`w-full py-4 px-4 rounded-2xl transition duration-300 shadow-sm hover:shadow-md text-black font-medium ${
              option === 'qr' 
                ? 'bg-blue-200 border-2 border-blue-400'
                : 'bg-blue-50 border border-blue-200'
            }`}
            >
            SCAN QR CODE
          </button>
          
          <button
            onClick={handleTextClick}
            className={`w-full py-4 px-4 rounded-2xl transition duration-300 shadow-sm hover:shadow-md text-black font-medium ${
              option === 'phone' 
                ? 'bg-blue-200 border-2 border-blue-400'
                : 'bg-blue-50 border border-blue-200'
            }`}
            >
            SEND BY TEXT
          </button>
          
          <button
            onClick={handleEmailClick}
            className={`w-full py-4 px-4 rounded-2xl transition duration-300 shadow-sm hover:shadow-md text-black font-medium ${
              option === 'email' 
                ? 'bg-blue-200 border-2 border-blue-400'
                : 'bg-blue-50 border border-blue-200'
            }`}
            >
            SEND BY EMAIL
          </button>
        </div>
      </div>


      <div className="flex flex-col items-center w-full max-w-md mx-auto rounded-3xl">
      {/* Conditional Sections */}
      {option === 'qr' && (
        <div className="mt-6 bg-white shadow-md rounded-lg p-6 w-full">
          <h2 className="text-lg font-semibold text-gray-800 mb-4 pb-2 border-b">Scan QR Code</h2>
          <div className="flex justify-center">
            {reviewUrl ? 
              <QRCodeGenerator
              reviewUrl={reviewUrl}
            />
            : /* Placeholder for QR code image */
              <div className="w-64 h-64 border-2 border-dashed border-gray-300 flex items-center justify-center">
                <p className="text-gray-500">QR Code Image</p>
            </div>
            }
          </div>
          <div className="mt-6 flex justify-center">
          </div>
        </div>
      )}

      {option === 'email' && (
        <div className="mt-6 bg-white shadow-md rounded-lg p-6 w-full">
          <form onSubmit={handleEmailSubmit}>
            <div className="mb-6">
              <h2 className="text-lg font-semibold text-gray-800 mb-4 pb-2 border-b">Customer Information</h2>
              
              <div className="mb-4">
                <label htmlFor="customerName" className="block text-sm font-medium text-gray-700 mb-1">
                  Customer Name
                </label>
                <input
                  type="text"
                  id="customerName"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  placeholder="John Doe"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  required
                  disabled={loading}
                />
              </div>

              <div className="mb-4">
                <label htmlFor="customerEmail" className="block text-sm font-medium text-gray-700 mb-1">
                  Customer Email
                </label>
                <input
                  type="email"
                  id="customerEmail"
                  value={customerEmail}
                  onChange={(e) => setCustomerEmail(e.target.value)}
                  placeholder="customer@example.com"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  required
                  disabled={loading}
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between pt-4 border-t">
              <button
                type="submit"
                className="mb-2 px-4 py-2 bg-blue-600 border border-transparent rounded-md text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                disabled={loading}
              >
                {loading ? 'Opening Email App...' : 'Send Review Request'}
              </button>
            </div>
          </form>
        </div>
      )}

      {option === 'phone' && (
        <div className="mt-6 bg-white shadow-md rounded-lg p-6 w-full">
          <form onSubmit={handlePhoneSubmit}>
            <div className="mb-6">
              <h2 className="text-lg font-semibold text-gray-800 mb-4 pb-2 border-b">Customer Information</h2>
              
              <div className="mb-4">
                <label htmlFor="customerName" className="block text-sm font-medium text-gray-700 mb-1">
                  Customer Name
                </label>
                <input
                  type="text"
                  id="customerName"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  placeholder="John Doe"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  required
                  disabled={loading}
                />
              </div>

              <div className="mb-4">
                <label htmlFor="customerPhone" className="block text-sm font-medium text-gray-700 mb-1">
                  Customer Phone
                </label>
                <input
                  type="tel"
                  id="customerPhone"
                  value={customerPhone}
                  onChange={(e) => setCustomerPhone(e.target.value)}
                  placeholder="123-456-7890"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  required
                  disabled={loading}
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between pt-4 border-t">
              <button
                type="submit"
                className="mb-2 px-4 py-2 bg-blue-600 border border-transparent rounded-md text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                disabled={loading}
              >
                {loading ? 'Sending Text...' : 'Send Review Request'}
              </button>
            </div>
          </form>
        </div>
      )}
      </div>


    </div>
  );
};

export default Dashboard;