// src/components/BusinessForm.js
import React, { useState, useEffect } from 'react';

const initialFormState = {
  name: '',
  type: '',
  address: {
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    formatted: ''
  },
  phone: '',
  website: '',
  googlePlaceId: '',
  emailTemplate: {
    subject: 'We value your feedback!',
    message: 'Thank you for choosing us! \n\n We would love to hear about your experience. \n\n Your feedback helps others discover our services.'
  }
};

const businessTypes = [
  'Restaurant', 
    'Retail', 
    'Service', 
    'Healthcare', 
    'Professional', 
    'Home Services',
    'Beauty & Wellness',
    'Automotive',
    'Education',
    'Other'
];

const BusinessForm = ({ business, onSubmit }) => {
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState({});
  const [activeStep, setActiveStep] = useState(0);

  const steps = ['1', '2', '3'];

  useEffect(() => {
    if (business) {
      const formattedBusiness = {
        ...business,
        address: {
          street: business.address?.street || '',
          city: business.address?.city || '',
          state: business.address?.state || '',
          zipCode: business.address?.zipCode || '',
          country: business.address?.country || '',
          formatted: business.address?.formatted || ''
        },
        emailTemplate: {
          subject: business.emailTemplate?.subject || 'We value your feedback!',
          message: business.emailTemplate?.message || initialFormState.emailTemplate.message
        }
      };
      setFormData(formattedBusiness);
    }
  }, [business]);

  // Handle simple field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Clear error when field is edited
    setErrors({ ...errors, [name]: null });
  };

  // Handle nested address fields
  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      address: {
        ...formData.address,
        [name]: value
      }
    });
    // Clear error
    setErrors({ ...errors, [`address.${name}`]: null });
  };

  // Handle template fields
  const handleTemplateChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      emailTemplate: {
        ...formData.emailTemplate,
        [name]: value
      }
    });
  };

  const validateStep = () => {
    const newErrors = {};
    
    // Basic Info Validation
    if (activeStep === 0) {
      if (!formData.name.trim()) {
        newErrors.name = 'Business name is required';
      }
      if (formData.phone && !/^[\d\s\-\(\)\.+]+$/.test(formData.phone)) {
        newErrors.phone = 'Please enter a valid phone number';
      }
      if (formData.website && !/^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/.*)?$/.test(formData.website)) {
        newErrors.website = 'Please enter a valid website URL';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep()) {
      setActiveStep(prev => Math.min(prev + 1, steps.length - 1));
    }
  };

  const handlePrevious = () => {
    setActiveStep(prev => Math.max(prev - 1, 0));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateStep()) {
      onSubmit(formData);
    }
  };

  return (
    <div className="max-w-3xl mx-auto pb-4 pt-1 px-4">
      <form onSubmit={handleSubmit} className="space-y-8">
        
        {/* Step Indicator */}
        <div className="flex justify-center flex-wrap mb-6">
          {steps.map((step, index) => (
            <div key={index} className={`px-4 py-2 mt-1 mx-1 rounded-full ${activeStep === index ? 'border border-gray-400' : 'bg-gray-100 text-gray-600'}`}>
              {step}
            </div>
          ))}
        </div>

        {/* Basic Info */}
        {activeStep === 0 && (
          <div>
            <h3 className="text-xl font-semibold">Basic Information</h3>
            <div className="space-y-4">
              <div>
                <label>Business Name *</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full border rounded-lg p-2"
                />
                {errors.name && <p className="text-red-600">{errors.name}</p>}
              </div>

              <div>
              <label htmlFor="type" className="block text-sm font-medium text-gray-700 mb-2">
                Business Type
              </label>
              <select
                id="type"
                name="type"
                value={formData.type}
                onChange={handleChange}
                className="w-full border rounded-lg p-2"
              >
                <option value="">Select a type</option>
                {businessTypes.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>

              <div>
                <label>Phone Number</label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full border rounded-lg p-2"
                  placeholder="(123) 456-7890"
                />
                {errors.phone && <p className="text-red-600">{errors.phone}</p>}
              </div>

              <div>
                <label>Website</label>
                <input
                  type="text"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                  className="w-full border rounded-lg p-2"
                  placeholder="www.yourbusiness.com"
                />
                {errors.website && <p className="text-red-600">{errors.website}</p>}
              </div>
            </div>
          </div>
        )}

        {/* Address - skip this step */}
        {/* {activeStep === 1 && (
          <div>
            <h3 className="text-xl font-semibold">Address</h3>
            <div className="space-y-4">
              <div>
                <label>Street Address</label>
                <input
                  type="text"
                  name="street"
                  value={formData.address.street}
                  onChange={handleAddressChange}
                  className="w-full border rounded-lg p-2"
                />
              </div>
              <div>
                <label>City</label>
                <input
                  type="text"
                  name="city"
                  value={formData.address.city}
                  onChange={handleAddressChange}
                  className="w-full border rounded-lg p-2"
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label htmlFor="state" className="block text-sm font-medium text-gray-700 mb-2">
                  State
                </label>
                <input
                  type="text"
                  id="state"
                  name="state"
                  value={formData.address.state}
                  onChange={handleAddressChange}
                  className="block w-full border rounded-lg py-3 px-4 bg-white border-gray-200 focus:border-blue-500"
                  placeholder="CA"
                />
              </div>
              <div>
                <label htmlFor="zipCode" className="block text-sm font-medium text-gray-700 mb-2">
                  ZIP / Postal
                </label>
                <input
                  type="text"
                  id="zipCode"
                  name="zipCode"
                  value={formData.address.zipCode}
                  onChange={handleAddressChange}
                  className="block w-full border rounded-lg py-3 px-4 bg-white border-gray-200 focus:border-blue-500"
                  placeholder="94103"
                />
              </div>
              </div>
              
            </div>
          </div>
        )} */}

        {/* Google Business ID  */}
        {activeStep == 1 && (
          <div>
          <h3 className="text-xl font-semibold">Google Integration</h3>
          <div className="space-y-4">
              <label htmlFor="googlePlaceId" className="block text-sm font-medium text-gray-700 mb-2">
                Google Place ID
              </label>
              <input
                type="text"
                id="googlePlaceId"
                name="googlePlaceId"
                value={formData.googlePlaceId}
                onChange={handleChange}
                className="block w-full border rounded-lg py-3 px-4 bg-white border-gray-200 focus:border-blue-500"
                placeholder="ChIJN1t_tDeuEmsRUsoyG83frY4"
              />
              <div className="mt-4 space-y-2">
                <a 
                  href="https://developers.google.com/maps/documentation/places/web-service/place-id"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block text-sm text-blue-600 hover:text-blue-800"
                >
                  🔍 Find your Place ID on Google's Place ID Finder
                </a>
                <a 
                  href="https://whitespark.ca/blog/how-to-find-the-google-place-id-for-any-google-business-profile/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block text-sm text-blue-600 hover:text-blue-800"
                >
                  📍 Alternative method to find your Place ID
                </a>
                <a 
                  href="mailto:arturo@freshwebagency.co"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block text-sm text-blue-600 hover:text-blue-800"
                >
                  📥 For support, contact arturo@freshwebagency.co
                </a>
              </div>
            </div>
          </div>
        )}

        {/* Email template */}
        {activeStep === 2 && (
          <div>
            <h3 className="text-xl font-semibold">Review Email Template</h3>
            <div className="space-y-4">
            <div>
              <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-2">
                Email Subject
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.emailTemplate.subject}
                onChange={handleTemplateChange}
                className="block w-full border rounded-lg py-3 px-4 bg-white border-gray-200 focus:border-blue-500"
              />
            </div>

            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-2">
                Email Message
              </label>
              <textarea
                id="message"
                name="message"
                rows={6}
                value={formData.emailTemplate.message}
                onChange={handleTemplateChange}
                className="block w-full border rounded-lg py-3 px-4 bg-white border-gray-200 focus:border-blue-500"
              />
              <p className="text-xs text-gray-500 mt-1">
                This message will be customized with your review link (you don't need to add it).
              </p>
            </div>

            </div>
          </div>
        )}

        

        {/* Navigation Buttons */}
        <div className="flex justify-between mt-6">
          <button
            type="button"
            onClick={handlePrevious}
            disabled={activeStep === 0}
            className="px-4 py-2 rounded bg-gray-300 hover:bg-gray-400"
          >
            Previous
          </button>
          {activeStep < steps.length - 1 && (
            <button
              type="button"
              onClick={handleNext}
              className="px-4 py-2 rounded bg-blue-600 text-white hover:bg-blue-700"
            >
              Next
            </button>
          )}
          {activeStep === steps.length - 1 && (
            <button
              type="submit"
              className="px-4 py-2 rounded bg-green-600 text-white hover:bg-green-700"
            >
              Add Business
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default BusinessForm;