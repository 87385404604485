import React, { useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

const QRCodeGenerator = ({ reviewUrl }) => {
  
  return (
    <div className="qr-code-container">
        <div className="flex justify-center my-6 p-4 bg-white border border-gray-200 rounded-md">
        <QRCodeCanvas 
          value={reviewUrl} 
          size={256}
          level="H"
          renderAs="svg"
        />
      </div>
    </div>
  );
};

export default QRCodeGenerator;