import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useAuth } from '../hooks/useAuth';
import Button from '../components/Button';

function Login() {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleRequestOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post('/api/auth/request-otp', { email },
        { 
          headers: { 
            'Content-Type': 'application/json'
          },
          withCredentials: true 
        }
      );
      setOtpSent(true);
      toast.success('Verification code sent');
    } catch (error) {
      toast.error(error.response?.data?.error || 'Failed to send OTP');
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('/api/auth/verify-otp', { email, otp });
      login(response.data.token, response.data.user);
      toast.success('Login successful');
      navigate('/dashboard');
    } catch (error) {
      toast.error(error.response?.data?.error || 'Failed to verify OTP');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-lg mx-auto pt-12 text-center">
      <h2 className="text-3xl md:text-4xl font-bold mb-10">Login/ Sign up</h2>
      {!otpSent ? (
        <form onSubmit={handleRequestOtp}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="w-full p-2 mb-4 border rounded"
            required
            disabled={loading}
          />
          <Button
            type="submit"
            className="w-full"
            disabled={loading}
          >
            {loading ? 'Sending...' : 'Request login code'}
          </Button>
        </form>
      ) : (
        <form onSubmit={handleVerifyOtp}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="w-full p-2 mb-4 border rounded"
            required
            disabled={loading}
          />
          <input
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            placeholder="Enter verification code"
            className="w-full p-2 mb-4 border rounded"
            required
            disabled={loading}
          />
          <p className='mb-4 text-gray-400'>
            {otpSent ? '✓ Email sent' : ''}
          </p>
          <Button
            type="submit"
            className="w-full"
            disabled={loading}
          >
            {loading ? 'Verifying...' : 'Continue'}
          </Button>
        </form>
      )}
    </div>
  );
}

export default Login;