import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button';

function Navbar() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleGetStarted = () => {
      navigate('/login');
  };

  const goToAdmin = () => {
    navigate('/dashboard');
  };

  const goToHome = () => {
    navigate('/home');
  };

  return (
    <nav className="">
      <div className="container mx-auto px-4 py-4 flex justify-center items-center">
        {/* <Link to="/" className="text-lg font-bold">The Review App</Link> */}
        <div>
          {user ? (
            <div className='flex justify-center space-x-8'>
                <button
                    className="text-md text-gray-600 transition-all duration-300 hover:cursor-pointer hover:underline hover:text-black"
                    onClick={goToHome}>
                  Home
                </button>
                <button
                  className="text-md text-gray-600 transition-all duration-300 hover:cursor-pointer hover:underline hover:text-black"
                  onClick={goToAdmin}>
                  Admin
                </button>
              <button
                className="text-md text-gray-600 transition-all duration-300 hover:cursor-pointer hover:underline hover:text-black"
                onClick={logout}>
                  Logout
                </button>
            </div>
          ) : (
            <button
                className="text-md text-gray-600 transition-all duration-300 hover:cursor-pointer hover:underline hover:text-black"
                onClick={handleGetStarted}>
              Login
            </button>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;