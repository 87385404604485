import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import Button from '../components/Button';
import LoadingSpinner from '../components/LoadingSpinner';

function RequestReview() {
  const { id } = useParams(); // Get business ID from URL
  const navigate = useNavigate();
  
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [loading, setLoading] = useState(false);
  const [fetchingBusiness, setFetchingBusiness] = useState(true);
  const [business, setBusiness] = useState(null);

  // Fetch business data on component mount
  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        const token = localStorage.getItem('token');
        
        if (!token) {
          navigate('/login');
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/business/${id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        setBusiness(response.data.business);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          navigate('/login');
        } else if (error.response && error.response.status === 404) {
          toast.error('Business not found');
          navigate('/dashboard');
        } else {
          toast.error('Failed to load business data');
          console.error('Error fetching business:', error);
        }
      } finally {
        setFetchingBusiness(false);
      }
    };

    fetchBusinessData();
  }, [id, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      // Format the review URL - use Google Place ID if available, otherwise create a generic search URL
      let reviewUrl = '';
      if (business?.googlePlaceId) {
        reviewUrl = `https://search.google.com/local/writereview?placeid=${business.googlePlaceId}`;
      } else {
        // If no place ID, create a search URL with the business name and maybe address
        const searchTerm = `${business?.name} ${business?.address?.city || ''}`.trim();
        reviewUrl = `https://www.google.com/search?q=${encodeURIComponent(searchTerm)}`;
      }
      
      // Create the email content
      var subject;
      var body;
      if(business?.emailTemplate?.message) {
        subject = encodeURIComponent(`${business?.emailTemplate?.subject}`);
        body = encodeURIComponent(`${business?.emailTemplate?.message} \n\n` + `Visit this link to leave a review: ${reviewUrl}\n\n`);
      } else {
        subject = encodeURIComponent(`Please Review ${business?.name}`);
        body = encodeURIComponent(
          `Hi ${customerName},\n\n` +
          `Thank you for choosing ${business?.name}! We'd love to hear about your experience.\n\n` +
          `Could you please take a moment to leave us a review? Your feedback helps us improve our services and helps others find us.\n\n` +
          `Click here to leave a review: ${reviewUrl}\n\n` +
          `Thank you for your time!\n\n` +
          `Best regards,\n` +
          `The ${business?.name} Team`
        );
      }
      
      // Open the default email client
      window.location.href = `mailto:${customerEmail}?subject=${subject}&body=${body}`;
      
      toast.success('Email app opened successfully');
      
      setTimeout(() => {
        // setCustomerEmail('');
        // setCustomerName('');
        setLoading(false);
      }, 1000);
      
    } catch (error) {
      toast.error('Failed to open email application');
      console.error('Error:', error);
      setLoading(false);
    }
  };

  if (fetchingBusiness) {
    return <LoadingSpinner />;
  }

  if (!business) {
    return (
      <div className="container mx-auto px-4 py-8 max-w-4xl">
        <div className="bg-red-50 border border-red-200 text-red-800 rounded-md p-4">
          <p>Business not found. It may have been deleted or you don't have permission to view it.</p>
          <button 
            onClick={() => navigate('/dashboard')}
            className="mt-4 text-blue-600 hover:text-blue-800 font-medium"
          >
            Return to Dashboard
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <div className="mb-6 flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Request a Review</h1>
          <p className="text-gray-600 mt-1">
            For {business.name}
          </p>
        </div>
        <button
          onClick={() => navigate('/dashboard')}
          className="text-gray-600 hover:text-gray-900 flex items-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
          </svg>
          Back to Dashboard
        </button>
      </div>

      <div className="bg-white shadow-md rounded-lg p-6">
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <h2 className="text-lg font-semibold text-gray-800 mb-4 pb-2 border-b">Customer Information</h2>
            
            <div className="mb-4">
              <label htmlFor="customerName" className="block text-sm font-medium text-gray-700 mb-1">
                Customer Name
              </label>
              <input
                type="text"
                id="customerName"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                placeholder="John Doe"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                required
                disabled={loading}
              />
            </div>

            <div className="mb-4">
              <label htmlFor="customerEmail" className="block text-sm font-medium text-gray-700 mb-1">
                Customer Email
              </label>
              <input
                type="email"
                id="customerEmail"
                value={customerEmail}
                onChange={(e) => setCustomerEmail(e.target.value)}
                placeholder="customer@example.com"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                required
                disabled={loading}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-between pt-4 border-t">
            <button
              type="button"
              onClick={() => navigate('/dashboard')}
              className="mb-2 px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
            <Button
              type="submit"
              className="mb-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              disabled={loading}
            >
              {loading ? 'Opening Email App...' : 'Send Review Request'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RequestReview;