// src/components/BusinessCard.js
import React from 'react';

const BusinessCard = ({ 
  business, 
  isSelected, 
  onSelect, 
  onEdit, 
  onDelete, 
  onRequestReview,
  onViewReviews,
  onGoHome,
}) => {
  return (
    <div 
      className={`border rounded-lg overflow-hidden transition-all duration-200 
        ${isSelected 
          ? 'border-blue-500 bg-blue-50 shadow-md' 
          : 'border-gray-200 bg-white hover:border-blue-300 hover:shadow-sm'}
      `}
    >
      <div 
        className="p-4 cursor-pointer"
        onClick={onSelect}
      >
        <h3 className="font-medium text-lg text-gray-800">{business.name}</h3>
        {/* <p className="text-gray-500 text-sm mt-1">
          {business.type || 'Service Business'}
        </p> */}
        {business.address?.formatted && (
          <p className="text-gray-600 text-sm mt-2 truncate">
            {business.address.formatted}
          </p>
        )}
      </div>
      
      <div className="bg-gray-50 px-4 py-3 border-t border-gray-100 flex justify-between">
        <div className="flex space-x-4">
          <button
            onClick={onGoHome}
            className="text-black hover:text-blue-700 text-md font-medium transition"
            title="Delete business"
          >
            Request Review
          </button>
        </div>
        <div className="flex space-x-4">
          {/* <button
            onClick={onRequestReview}
            className="text-blue-600 hover:text-blue-800 text-sm font-medium transition"
            title="Request a new review"
          >
            Request Review
          </button> */}
          {/* <button
            onClick={onViewReviews}
            className="text-blue-600 hover:text-blue-800 text-sm font-medium transition"
            title="View all reviews"
          >
            Reviews
          </button> */}
          {/* hide option to delete */}
          <button
            onClick={onEdit}
            className="text-gray-600 hover:text-blue-700 text-sm font-medium transition"
            title="Edit business"
          >
            Edit
          </button>
          <button
            onClick={onDelete}
            className="text-gray-600 hover:text-red-700 text-sm font-medium transition"
            title="Delete business"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessCard;