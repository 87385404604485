import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import axios from 'axios';
import { Toaster } from 'react-hot-toast';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthContext';


// Set up axios defaults
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <AuthProvider>
          <Toaster
            position="top-right"
            toastOptions={{
              className: 'text-sm p-4 min-w-[250px] sm:min-w-[300px]',
              onClick: (toast) => {
                toast.dismiss(); // Closes the toast when clicked
              }
            }}
          />
          <App />
      </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
