import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button';

function Home() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is logged in by looking for token in localStorage
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  const handleGetStarted = () => {
    if (isLoggedIn) {
      navigate('/dashboard');
    } else {
      navigate('/login');
    }
  };

  return (
    <div className="flex flex-col text-center pt-12 space-y-6">
      <h1 className="text-3xl md:text-4xl font-bold">The Review App For Local Businesses.</h1>
      <p className="text-lg md:text-xl">Easily request reviews from your clients and build your online credibility.</p>
      <div className='max-w-[200px] mx-auto'>
        <Button onClick={handleGetStarted}>Get Started</Button>
      </div>
    </div>
  );
}

export default Home;